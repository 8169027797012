"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBranchData = exports.isBranchModule = exports.ModuleType = void 0;
// eslint-disable-next-line no-shadow
var ModuleType;
(function (ModuleType) {
    ModuleType["ContentPageModule"] = "ContentPageModule";
    ModuleType["VacancyQuestionsModule"] = "VacancyQuestionsModule";
    ModuleType["SpokenLanguageTestModule"] = "SpokenLanguageTestModule";
    ModuleType["RedirectToClientCandidateModule"] = "RedirectToClientCandidateModule";
    ModuleType["ChatProficiencyAssessmentModule"] = "ChatProficiencyAssessmentModule";
    ModuleType["TypingModule"] = "TypingModule";
    ModuleType["NOAModule"] = "NOAModule";
    ModuleType["LearningAgilityModule"] = "LearningAgilityModule";
    ModuleType["PersonalityQuestionnaireModule"] = "PersonalityQuestionnaireModule";
    ModuleType["JobKnowledgeTestModule"] = "JobKnowledgeTestModule";
    ModuleType["SystemCheckerModule"] = "SystemCheckerModule";
    ModuleType["InternetSpeedTestModule"] = "InternetSpeedTestModule";
    ModuleType["PersonalityPrintModule"] = "PersonalityPrintModule";
    ModuleType["VirtualInterviewModule"] = "VirtualInterviewModule";
    ModuleType["SituationalJudgmentTestModule"] = "SituationalJudgmentTestModule";
    ModuleType["AvailabilityModule"] = "AvailabilityModule";
    ModuleType["PersonalInformationModule"] = "PersonalInformationModule";
    ModuleType["LanguageTestModule"] = "LanguageTestModule";
    ModuleType["MultiLocationPickerModule"] = "MultiLocationPickerModule";
    ModuleType["PymetricsModule"] = "PymetricsModule";
    ModuleType["BranchModule"] = "BranchModule";
    ModuleType["EndPage"] = "EndPage";
})(ModuleType = exports.ModuleType || (exports.ModuleType = {}));
const isBranchModule = (obj) => {
    return Boolean(obj && (obj === null || obj === void 0 ? void 0 : obj.type) === ModuleType.BranchModule);
};
exports.isBranchModule = isBranchModule;
const isBranchData = (obj) => {
    return (obj &&
        typeof obj === 'object' &&
        typeof obj.id === 'string' &&
        typeof obj.name === 'string' &&
        typeof obj.description === 'string' &&
        typeof obj.depth === 'number' &&
        typeof obj.branchModuleId === 'string' &&
        typeof obj.navigationRule === 'object' && // warn naive checks for object type
        typeof obj.intro === 'object' // warn naive checks for object type
    );
};
exports.isBranchData = isBranchData;
